@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: 'Monument Extended';
  src: local('Monument Extended'), url(./fonts/MonumentExtended-Regular.otf)
      format('opentype');
}

@font-face {
  font-family: 'Ade';
  src: local('Ade'), url(./fonts/Ade-Display.otf) 
    format('opentype');
}

@font-face {
  font-family: 'Curve';
  src: local('Curve'), url(./fonts/Curve-Bold.otf) 
    format('opentype');
}

@font-face {
  font-family: 'Editorial New';
  src: local('Editorial New'), url(./fonts/PPEditorialNew-Regular.otf) 
    format('opentype');
}

@font-face {
  font-family: 'BLMelody';
  src: local('BlMelody'), url(./fonts/BLMelody-Regular.otf)
    format('opentype');
}

@font-face {
  font-family: 'PPEditorial';
  src: local('PPEditorial'), url(./fonts/PPEditorialNew-Regular.otf)
    format('opentype');
}

@font-face {
  font-family: 'Zeitung';
  src: local('Zeitung'), url("./fonts/ZeitungMonoPro-Regular.otf")
    format('opentype');
}

@font-face {
  font-family: 'PPAcma';
  src: local('PPAcma'), url("./fonts/PPAcma-Semibold.otf")
    format('opentype');
}

@font-face {
  font-family: 'PPAcma-light';
  src: local('PPAcma-light'), url("./fonts/PPAcma-Light.otf")
    format('opentype');
}

@font-face {
  font-family: 'MADE-Gentile';
  src: local('MADE-Gentile'), url("./fonts/MADE-Gentle.otf")
    format('opentype');
}

@font-face {
  font-family: 'Roslindale';
  src: local('Roslindale'), url("./fonts/RoslindaleText-Regular.otf")
    format('opentype');
}
@font-face{
  font-family: 'RoslindaleItalic';
  src: local('RoslindaleItalic'), url("./fonts/RoslindaleText-Italic.otf")
    format('opentype');
}
@font-face {
  font-family: 'RoslindaleBold';
  src: local('RoslindaleBold'), url("./fonts/RoslindaleText-Bold.otf")
    format('opentype');
}
@font-face {
  font-family: 'RoslindaleLight';
  src: local('RoslindaleDisplay-Light'), url("./fonts/RoslindaleDisplayCondensed-Light.otf")
    format('opentype');
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
